<template>
  <div class="payment-success">
    <img
      src="https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/components/success.png"
      class="payment-success__icon"
    />
    <div class="payment-success__heading">{{ $t('payment.success.title') }}</div>

    <div class="payment-success__operation">
      <op-button
        class="payment-success__operation__back-to-shopping"
        type="outline"
        @click="goProductList"
      >{{ $t('payment.success.continueShopping') }}</op-button>
      <op-button
        v-if="isAlreadyLogin"
        @click="goMyAccount"
      >{{ $t('payment.success.viewOrder') }}</op-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },

  computed: {
    isAlreadyLogin() {
      return this.$store.state.isAlreadyLogin
    },
  },

  methods: {
    goProductList() {
      this.$router.push('/products/all')
    },
    goMyAccount() {
      this.$router.push('/account')
    },
  },
}
</script>
<style lang="less" scoped>
.payment-success {
  width: 1440px;
  max-width: calc(100% - 80px);
  padding-top: 100px;
  margin: 0 auto;
  &__icon {
    display: block;
    width: 96px;
    height: 96px;
    margin: 0 auto;
    object-fit: cover;
  }
  &__heading {
    margin-top: 40px;
    font-size: 36px;
    font-weight: 500;
    line-height: 52px;
    color: var(--color-text);
    text-align: center;
  }

  &__operation {
    display: flex;
    justify-content: center;
    margin: 60px auto;
    &__back-to-shopping {
      margin-right: 16px;
    }
  }
}
@media (max-width: 1024px) {
  .payment-success {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .payment-success {
    max-width: calc(100% - 32px);
  }
}
</style>
